import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { locationsApi } from '../../axios';
import { Location } from '../../types';
import type { RootState } from '../store';

// TODO: `from` and `to` are some legacy variables. Let's see if we can fix them in the future
interface LocationsState {
  locations: Location[];
  page?: number;
  pages?: number;
  total?: number;
  from?: number;
  to?: number;
  loading: boolean;
  error?: string;
}

const locationsInitialState: LocationsState = {
  locations: [],
  loading: false,
  error: undefined,
};

// Async Thunks for API Calls:
const getLocations = createAsyncThunk('getLocations', async ({ page }: { page?: number }) => {
  const data = await locationsApi.getLocations(page);
  return data;
});
// Async Thunks for API Calls:
const getNextLocationsPage = createAsyncThunk('getNextLocationsPage', async (_, { getState }) => {
  const store = getState() as Record<string, LocationsState>;
  const currentPage = store.locations.page;
  const data = await locationsApi.getLocations(currentPage + 1);
  return data;
});

// Locations Slice with reducers:
export const locationsSlice = createSlice({
  name: 'locations',
  initialState: locationsInitialState,
  reducers: {
    cleanLocations: (state) => {
      state.locations = [];
      state.page = undefined;
      state.pages = undefined;
      state.total = undefined;
      state.from = undefined;
      state.to = undefined;
      state.loading = false;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getLocations.rejected, (state) => {
      state.locations = [];
      state.loading = false;
      state.error = 'Error at getting locations';
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.locations = action.payload.locations;
      state.page = action.payload.page;
      state.pages = action.payload.pages;
      state.total = action.payload.total;
      state.from = action.payload.from;
      state.to = action.payload.to;
      state.loading = false;
    });
    builder.addCase(getNextLocationsPage.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getNextLocationsPage.rejected, (state) => {
      state.loading = false;
      state.error = 'Error at getting the next locations';
    });
    builder.addCase(getNextLocationsPage.fulfilled, (state, action) => {
      state.locations = [...state.locations, ...action.payload.locations];
      state.page = action.payload.page;
      state.pages = action.payload.pages;
      state.total = action.payload.total;
      state.from = action.payload.from;
      state.to = action.payload.to;
      state.loading = false;
    });
  },
});

export { getLocations, getNextLocationsPage };
export const { cleanLocations } = locationsSlice.actions;
export const selectListObjects = (state: RootState) => state.listObjects;
export default locationsSlice.reducer;
