import { create } from 'zustand';

import { CurrentUser, currentUserApi, PremiumFlag } from '../axios';
import { English } from '../lang/en.js';
import { Spanish } from '../lang/es.js';
import { Portuguese } from '../lang/pt.js';
import { getQueryParams } from '../utils/utils';

export type LangTree = typeof English | typeof Spanish | typeof Portuguese;
export type LangParam = 'en' | 'es' | 'pt';

interface UserDataStore {
  permissions: string[];
  featureFlags: string[];
  premiumFlags: Record<string, PremiumFlag>;
  loading: boolean;
  currentUser: CurrentUser | null;
  initializeUserData: () => Promise<void>;
  lang: LangTree;
}

const langDict: Record<LangParam, LangTree> = { en: English, es: Spanish, pt: Portuguese };
const langParam = (getQueryParams().lang || 'en') as LangParam;

const useUserDataStore = create<UserDataStore>((set) => ({
  permissions: [],
  featureFlags: [],
  premiumFlags: {},
  loading: true,
  currentUser: null,
  lang: langDict[langParam],
  initializeUserData: async () => {
    try {
      const [permissions, flagsResponse, user] = await Promise.all([
        currentUserApi.getCurrentUserPermissions(),
        currentUserApi.getCurrentUserFlags(),
        currentUserApi.getCurrentUserName(),
      ]);

      set({
        permissions,
        featureFlags: flagsResponse.flags,
        premiumFlags: flagsResponse.premium_flags || {},
        currentUser: user,
        loading: false,
      });
    } catch (error) {
      console.error('Failed to initialize user data:', error);
      set({ loading: false });
    }
  },
}));

useUserDataStore.getState().initializeUserData();

export { useUserDataStore };
