import {
  CreateTaskAssignBody,
  GetFilterMetadataResponse,
  GetTaskAssignsResponse,
  GetTasksParams,
} from '../types/taskAssigns';
import { client } from './index';

const getTasks = async ({
  page = 1,
  taskAssignID,
  userId,
  locationId,
  taskFormId,
  state,
  startDate,
  endDate,
  sortBy,
  order,
}: GetTasksParams) => {
  const params = new URLSearchParams();

  if (page) params.append('page', page.toString());
  if (taskAssignID) params.append('assignID', taskAssignID);
  if (userId) params.append('user_id', userId.toString());
  if (locationId) params.append('location_id', locationId.toString());
  if (taskFormId) params.append('task_form_id', taskFormId.toString());
  if (state) params.append('state', state);
  if (startDate) params.append('start', startDate);
  if (endDate) params.append('end', endDate);
  if (sortBy) params.append('sort_by', sortBy);
  if (order) params.append('order', order);

  const response = await client.get<GetTaskAssignsResponse>(
    `/task_assigns_react?${params.toString()}`,
  );
  const { data } = response;
  return data;
};

const getTasksBoxes = async ({
  page = 1,
  taskAssignID,
  userId,
  locationId,
  taskFormId,
  state,
  startDate,
  endDate,
  sortBy,
  order,
}: GetTasksParams) => {
  const params = new URLSearchParams();

  if (page) params.append('page', page.toString());
  if (taskAssignID) params.append('assignID', taskAssignID);
  if (userId) params.append('user_id', userId.toString());
  if (locationId) params.append('location_id', locationId.toString());
  if (taskFormId) params.append('task_form_id', taskFormId.toString());
  if (state) params.append('state', state);
  if (startDate) params.append('start', startDate);
  if (endDate) params.append('end', endDate);
  if (sortBy) params.append('sort_by', sortBy);
  if (order) params.append('order', order);

  const response = await client.get<GetTaskAssignsResponse>(
    `/task_assigns_react_boxes?${params.toString()}`,
  );
  const { data } = response;
  return data;
};

const getLightFilterMetadata = async () => {
  const response = await client.get<GetFilterMetadataResponse>('/filter_metadata');
  const { data } = response;
  return data;
};

const createTaskAssign = async (taskAssign: CreateTaskAssignBody) => {
  const response = await client.post('/task_assigns_create.json', taskAssign);
  const { data } = response;
  return data;
};

const updateTaskAssign = async (taskId: number, taskAssign: CreateTaskAssignBody) => {
  const response = await client.put(`/task_assign_update/${taskId}`, taskAssign, {
    headers: {
      accept: 'application/json',
    },
  });
  const { data } = response;
  return data;
};

const notifyUsers = async (taskID: number) => {
  const response = await client.post('/tasks_notify_react.json', { task_id: taskID });
  const { data } = response;
  return data;
};

const deleteTask = async (taskID: number) => {
  const response = await client.delete(`/task_assigns_delete/${taskID}`);
  const { data } = response;
  return data;
};

const getEditInfo = async (taskID: number) => {
  const response = await client.get(`/task_assign_edit/${taskID}`);
  const { data } = response;
  return data;
};

const bulkDeleteTasks = async (selectedTaskIds: number[]) => {
  const response = await client.post('/tasks_bulk_delete_react', { form_ids: selectedTaskIds });
  const { data } = response;
  return data;
};

const bulkNotifyUsers = async (selectedTaskIds: number[]) => {
  const response = await client.post('/bulk_notify_react', { form_ids: selectedTaskIds });
  const { data } = response;
  return data;
};

const updateTask = async (
  taskID: string,
  task: CreateTaskAssignBody['task_assign'],
  time: CreateTaskAssignBody['time'],
  todoValue: string,
) => {
  const taskAssign = {
    task_assign: task,
    time,
    'todo-value': todoValue,
  };
  const response = await client.put(`/task_assign_update/${taskID}`, taskAssign);
  const { data } = response;
  return data;
};

const getOldVersion = async () => {
  const response = await client.get('/old_task_assigner');
  const { data } = response;
  return data;
};

export interface locationInfo {
  value: string;
  label: string;
}

export const loadOptions = async (
  searchQuery: string,
  loadedOptions: locationInfo[],
  startId = 0,
) => {
  const params = new URLSearchParams();

  const lastId = loadedOptions.length > 0 ? loadedOptions[loadedOptions.length - 1].value : startId;
  params.append('start', lastId.toString());
  if (searchQuery != '') params.append('search', searchQuery);

  const request_url = `/filter_location?${params.toString()}`;
  const response = await client.get(request_url);

  const locationList = response.data.locations.map((entry: (string | number)[]) => ({
    value: entry[1].toString(),
    label: entry[0],
  }));

  return {
    options: locationList, // cada vez que se cumple hasMore, se agregan estos elementos
    hasMore: locationList.length >= 50,
  };
};

export const taskAssignsApi = {
  getLightFilterMetadata,
  notifyUsers,
  deleteTask,
  getEditInfo,
  createTaskAssign,
  updateTaskAssign,
  getTasks,
  bulkDeleteTasks,
  bulkNotifyUsers,
  updateTask,
  getOldVersion,
  getTasksBoxes,
  loadOptions,
};
