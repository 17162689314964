import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Button,
  colors,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';

import { useLanguage } from '../../../hooks';

const customStyles: Record<string, SxProps<Theme>> = {
  formControl: {
    margin: (theme: Theme) => theme.spacing(1),
    minWidth: '100px',
  },
  numberField: {
    margin: (theme: Theme) => theme.spacing(1),
    maxWidth: '70px',
  },
  button: {
    backgroundColor: '#1ab394',
    color: 'white',
  },
};

export interface TaskNotification {
  type: 'notification' | 'email';
  /* Period time */
  time: number;
  /* Minutes, hours, days, weeks, months, years */
  period: number;
}

export const createNotifString = (list: TaskNotification[]) => {
  return list
    .map(
      (notif: TaskNotification) =>
        `${notif.type == 'notification' ? 1 : 2}:${notif.time}:${notif.period}`,
    )
    .join('-');
};

export const viewNotificationsList = (
  open: boolean,
  list: TaskNotification[],
  setList: any,
  btnText: string,
) => {
  if (open) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {list.map((notif: TaskNotification, i: number) => {
          return (
            <div
              key={i.toString() + `${notif.time * notif.period}`}
              style={{
                justifyContent: 'center',
                borderStyle: 'solid',
                borderRadius: 5,
                borderColor: colors.grey[100],
                borderWidth: 1,
                marginBottom: 5,
              }}
            >
              <NotificationSetter
                notif={notif}
                setList={setList}
                index={i}
              />
              <IconButton
                style={{ backgroundColor: 'transparent' }}
                onClick={() => setList(undefined, i)}
                size='large'
              >
                <ClearIcon />
              </IconButton>
            </div>
          );
        })}
        <Button
          color='primary'
          variant='contained'
          startIcon={<AddIcon />}
          sx={customStyles.button}
          onClick={() => {
            setList({ type: 'notif', time: 15, period: 1 }, list.length);
          }}
        >
          {btnText}
        </Button>
      </div>
    );
  }
};

export const NotificationSetter = (props: {
  notif: TaskNotification;
  setList: any;
  index: number;
}) => {
  const { notif, setList, index } = props;

  const [notificationType, setNotificationType] = useState(notif.type);
  const [notificationTime, setNotificationTime] = useState(notif.time);
  const [timePeriod, setTimePeriod] = useState(notif.period);

  const lang = useLanguage();

  const handleTypeChange = (event: SelectChangeEvent<any>) => {
    setNotificationType(event.target.value);
  };

  const handlePeriodChange = (event: SelectChangeEvent<any>) => {
    setTimePeriod(event.target.value);
  };

  useEffect(
    () => setList({ type: notificationType, time: notificationTime, period: timePeriod }, index),
    [notificationType, notificationTime, timePeriod],
  );

  return (
    <>
      <FormControl sx={customStyles.formControl}>
        <Select
          id='demo-simple-select'
          value={notificationType}
          onChange={handleTypeChange}
        >
          <MenuItem value={'notification'}>{lang.task_assigner.notification}</MenuItem>
          <MenuItem value={'email'}>{lang.task_assigner.email}</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={customStyles.numberField}>
        <TextField
          id='standard-number'
          type='number'
          value={notificationTime}
          onChange={(e) => setNotificationTime(parseInt(e.target.value))}
          variant='standard'
        />
      </FormControl>
      <FormControl sx={customStyles.formControl}>
        <Select
          id='demo-simple-select'
          value={timePeriod}
          onChange={handlePeriodChange}
        >
          <MenuItem value={1}>{lang.task_assigner.minutes}</MenuItem>
          <MenuItem value={60}>{lang.task_assigner.hours}</MenuItem>
          <MenuItem value={1440}>{lang.task_assigner.days}</MenuItem>
          <MenuItem value={10080}>{lang.task_assigner.weeks}</MenuItem>
          <MenuItem value={43800}>{lang.task_assigner.months}</MenuItem>
          <MenuItem value={525600}>{lang.task_assigner.years}</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
