import {
  FormAnswer,
  FormAnswerChatMessage,
  FormAnswerState,
  ResumeStatisticsResponse,
  ResumeTaskForm,
  ResumeTaskFormsResponse,
} from '../types';
import { client } from './index';

async function getResumeStatistics() {
  const response = await client.get<ResumeStatisticsResponse>('/resume_statistics');
  const { data } = response;
  return data;
}

async function getFormAnswerStates() {
  const response = await client.get<FormAnswerState[]>('/form_answer_states.json');
  const { data } = response;
  return data;
}

async function updateFormAnswerState(
  formAnswerId: FormAnswer['id'],
  stateId: FormAnswerState['id'],
) {
  const response = await client.post('/change_form_state', {
    form_id: formAnswerId,
    form_state_id: stateId ?? 'clean',
  });
  const { data } = response;
  return data;
}

async function getFormAnswerHistory(formAnswerId: FormAnswer['id'], formId: FormAnswer['form_id']) {
  const response = await client.get(`/task_forms/${formId}/form_answers/${formAnswerId}/history`);
  const { data } = response;
  return data;
}

async function notifyUsersFromFormAnswerChat(
  message: string,
  userIds: FormAnswerChatMessage['user']['_id'][],
) {
  const response = await client.post('notify', { msg_body: message, user_ids: userIds });
  const { data } = response;
  return data;
}

async function getTaskFormsWithSearch(page: number, searchQuery?: string) {
  const params = new URLSearchParams();

  if (page) params.append('page', page.toString());
  if (searchQuery) params.append('search', searchQuery);

  const queryString = params.toString() ? `?${params.toString()}` : '';
  const url = `/resume_forms${queryString}`;

  const response = await client.get<ResumeTaskFormsResponse>(url);
  const { data } = response;
  return data;
}

async function getTaskForm(taskFormId: number) {
  const getTaskFormUrl = `/resume_form/${taskFormId}`;
  const response = await client.get<{ form: ResumeTaskForm }>(getTaskFormUrl);
  const { data } = response;
  return data;
}

export const resumeApi = {
  getResumeStatistics,
  getFormAnswerStates,
  updateFormAnswerState,
  getFormAnswerHistory,
  notifyUsersFromFormAnswerChat,
  getTaskFormsWithSearch,
  getTaskForm,
};
