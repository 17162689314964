import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { userListsApi } from '../../axios/lists';
import type { RootState } from '../../store/store';
import { newListType, userListsType } from '../../types/list';

const getLists = createAsyncThunk('getLists', async () => {
  const response = await userListsApi.getLists();
  const { userLists } = response;
  return userLists;
});

const createList = createAsyncThunk('createList', async (newList: newListType, thunkAPI) => {
  await userListsApi.createList(newList);
  await thunkAPI.dispatch(getLists());
});

const initialState: userListsType = {
  lists: [],
  loading: false,
  error: undefined,
};

export const userListsSlice = createSlice({
  name: 'userLists',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLists.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLists.fulfilled, (state, action) => {
      state.lists = action.payload;
      state.loading = false;
    });
    builder.addCase(getLists.rejected, (state) => {
      state.lists = [];
      state.loading = false;
      state.error = 'Error at getting user lists';
    });
    builder.addCase(createList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createList.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createList.rejected, (state) => {
      state.loading = false;
      state.error = 'Error at creating user list';
    });
  },
});

// export const { openSegment, closeSegment } = userListsSlice.actions;
export { getLists, createList };
export const selectUserLists = (state: RootState) => state.userLists;
export default userListsSlice.reducer;
