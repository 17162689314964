import { FormAnswersResponse } from '../types';
import { client } from './index';

async function getFormAnswers(searchQuery?: string, page?: number): Promise<FormAnswersResponse> {
  const params = new URLSearchParams();

  if (page) params.append('page', page.toString());
  if (searchQuery) params.append('search_form_name', searchQuery);

  const queryString = params.toString() ? `?${params.toString()}` : '';
  const url = `/form_answers_react${queryString}`;

  const response = await client.get<FormAnswersResponse>(url);
  const { data } = response;
  return data;
}

export const formAnswersApi = {
  getFormAnswers,
};
