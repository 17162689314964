import { FirebaseAuthConfig } from '../types/firestore';
import { client } from './index';

async function getFirebaseAuthTokens() {
  const response = await client.get<FirebaseAuthConfig>('/users/get_firebase_auth');
  const { data } = response;
  return data;
}

export const firestoreApi = {
  getFirebaseAuthTokens,
};
