import { newListType, newObjectType } from '../types';
import { client } from './index';

const getLists = async () => {
  const response = await client.get('/react_get_lists');
  const { data } = response;
  return data;
};

const createList = async (newList: newListType) => {
  const response = await client.post(
    '/react_create_list',
    {
      new_list: newList,
    },
    {
      headers: {
        accept: 'application/json',
      },
    },
  );
  const { data } = response;
  return data;
};

const getObjects = async (id?: string, metadataCode?: string, page?: number) => {
  const params = new URLSearchParams();
  if (metadataCode) params.append('metadata_code', metadataCode);
  if (id) params.append('id', id);
  if (page) params.append('page', page.toString());

  const queryString = params.toString() ? `?${params.toString()}` : '';
  const url = `/react_get_objects${queryString}`;

  const response = await client.get(url);
  const { data } = response;
  return data;
};

const createObject = async (listId: string, list_object: newObjectType) => {
  const response = await client.post('/react_create_object', {
    list_object,
    list_id: listId,
  });
  const { data } = response;
  return data;
};

const updateObject = async (id: string, list_object: newObjectType) => {
  const response = await client.put(`/react_update_object/${id}`, {
    list_object,
  });
  const { data } = response;
  return data;
};

const deleteObject = async (id: string) => {
  const response = await client.delete(`/react_delete_object/${id}`);
  const { data } = response;
  return data;
};

export const listObjectsApi = {
  getObjects,
  createObject,
  updateObject,
  deleteObject,
};

export const userListsApi = { getLists, createList };
