import { GroupBody, GroupHistory, UserGroup } from '../types';
import { client } from './index';

interface FetchGroupsInterface {
  groups: UserGroup[];
}

async function getGroups() {
  const response = await client.get<FetchGroupsInterface>('groups');
  const { data } = response;
  return data;
}

async function createGroup(body: GroupBody) {
  const response = await client.post<UserGroup>('/groups', body);
  const { data } = response;
  return data;
}

async function deleteGroup(groupId: number) {
  const response = await client.delete<UserGroup>(`/groups/${groupId}`);
  const { data } = response;
  return data;
}

async function updateGroup(id: number, body: GroupBody) {
  const response = await client.patch<UserGroup>(`/groups/${id}`, body);
  const { data } = response;
  return data;
}

async function getHistory(page: number) {
  const response = await client.get<GroupHistory>(`/groups/history?page=${page + 1}`);
  const { data } = response;
  return data;
}

async function bulkAddUsers(groupId: number, userIds: number[]) {
  const response = await client.put<UserGroup>(`/groups/bulk_add_users/${groupId}`, {
    mobile_user_ids: userIds,
  });
  const { data } = response;
  return data;
}

async function bulkRemoveUsers(groupId: number, userIds: number[]) {
  const response = await client.put<UserGroup>(`/groups/bulk_remove_users/${groupId}`, {
    user_ids: userIds,
  });
  const { data } = response;
  return data;
}

export const groupsApi = {
  getGroups,
  createGroup,
  deleteGroup,
  updateGroup,
  getHistory,
  bulkAddUsers,
  bulkRemoveUsers,
};
