import { createContext, ReactNode, useState } from 'react';

import { useFeatureFlags } from '../../hooks';

interface FormBuilderContextType {
  searchDataListModalOpen: boolean;
  setSearchDataListModalOpen: (value: boolean) => void;
  searchDataListModalFeature: boolean;
  listModalQuestionId: string;
  setListModalQuestionId: (value: string) => void;
  listModalTableId: string;
  setListModalTableId: (value: string) => void;
}

export const FormBuilderContext = createContext<FormBuilderContextType>({
  searchDataListModalOpen: false,
  setSearchDataListModalOpen: () => void 0,
  searchDataListModalFeature: false,
  listModalQuestionId: null,
  setListModalQuestionId: () => void 0,
  listModalTableId: null,
  setListModalTableId: () => void 0,
});

export const FormBuilderProvider = ({ children }: { children: ReactNode }) => {
  const { checkFlag } = useFeatureFlags();
  const searchDataListModalFeature = checkFlag('SearchDataListModal');

  const [searchDataListModalOpen, setSearchDataListModalOpen] = useState(false);
  const [listModalQuestionId, setListModalQuestionId] = useState<string>(null);
  const [listModalTableId, setListModalTableId] = useState<string>(null);

  return (
    <FormBuilderContext.Provider
      value={{
        searchDataListModalOpen,
        setSearchDataListModalOpen,
        searchDataListModalFeature,
        listModalQuestionId,
        setListModalQuestionId,
        listModalTableId,
        setListModalTableId,
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
};
