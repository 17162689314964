import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { addDays } from 'date-fns';

import { formAnswersApi } from '../../axios/formAnswers';
import { resumeApi } from '../../axios/resume';
import { useDebounce } from '../../hooks/useDebounce';
import { FormAnswer, FormAnswerState, ResumeFilters, ResumeStatistics } from '../../types';

interface ResumeContextType {
  loading: boolean;
  formAnswers: FormAnswer[];
  currentPage: number;
  totalPages: number;
  searchQuery: string;
  totalCount: number;
  selectedFormAnswers: FormAnswer['id'][];
  resumeStats: ResumeStatistics;
  allFormAnswersSelected: boolean;
  someFormAnswersSelected: boolean;
  formAnswerStates: Record<FormAnswerState['id'], FormAnswerState>;
  filters: {
    startDate: Date;
    endDate: Date;
  };
  handleSearch: (query: string) => void;
  handlePageChange: (page: number) => void;
  handleSelectFormAnswer: (formAnswerId: FormAnswer['id']) => void;
  handleSelectAllFormAnswers: () => void;
  changeFormAnswerState: (formAnswerId: FormAnswer['id'], state: FormAnswer['state_id']) => void;
}

const ResumeContext = createContext<ResumeContextType | null>(null);

export function ResumeProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState(false);
  const [formAnswers, setFormAnswers] = useState<FormAnswer[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFormAnswers, setSelectedFormAnswers] = useState<FormAnswer['id'][]>([]);
  const [resumeStats, setResumeStats] = useState<ResumeStatistics>({
    assignedTasks: 0,
    completedTasks: 0,
    formsAnswered: 0,
    locationsVisited: 0,
  });
  const [formAnswerStates, setFormAnswerStates] = useState<
    Record<FormAnswerState['id'], FormAnswerState>
  >({});
  const [filters, setFilters] = useState<ResumeFilters>({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
  });

  useEffect(() => {
    async function fetchFormAnswers() {
      setLoading(true);
      try {
        const { answers, total_pages, total_count } = await formAnswersApi.getFormAnswers(
          debouncedQuery ?? undefined,
          currentPage,
        );
        setFormAnswers(answers);
        setTotalCount(Number(total_count));
        setTotalPages(Number(total_pages));
        setResumeStats({
          assignedTasks: 0,
          completedTasks: 0,
          formsAnswered: Number(total_count),
          locationsVisited: 0,
        });
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
    fetchFormAnswers();
  }, [currentPage, debouncedQuery]);

  useEffect(() => {
    async function fetchFormAnswerStates() {
      const formAnswerStates = await resumeApi.getFormAnswerStates();
      setFormAnswerStates(
        formAnswerStates.reduce((acc, state) => {
          acc[state.id] = state;
          return acc;
        }, {} as Record<FormAnswerState['id'], FormAnswerState>),
      );
    }
    fetchFormAnswerStates();
  }, []);

  const debouncedSearch = useDebounce(() => {
    setDebouncedQuery(searchQuery);
  }, 500);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    debouncedSearch();
  };

  const allFormAnswersSelected =
    selectedFormAnswers.length > 0 && selectedFormAnswers.length === formAnswers.length;
  const someFormAnswersSelected =
    selectedFormAnswers.length > 0 && selectedFormAnswers.length < formAnswers.length;

  const handleSelectAllFormAnswers = () => {
    if (allFormAnswersSelected || someFormAnswersSelected) {
      setSelectedFormAnswers([]);
    } else {
      setSelectedFormAnswers(formAnswers.map((formAnswer) => formAnswer.id));
    }
  };

  const handleSelectFormAnswer = (formAnswerId: FormAnswer['id']) => {
    setSelectedFormAnswers(
      selectedFormAnswers.includes(formAnswerId)
        ? selectedFormAnswers.filter((id) => id !== formAnswerId)
        : [...selectedFormAnswers, formAnswerId],
    );
  };

  const changeFormAnswerState = async (
    formAnswerId: FormAnswer['id'],
    state: FormAnswer['state_id'],
  ) => {
    setFormAnswers(
      formAnswers.map((formAnswer) =>
        formAnswer.id === formAnswerId ? { ...formAnswer, state_id: state } : formAnswer,
      ),
    );
    try {
      await resumeApi.updateFormAnswerState(formAnswerId, state);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ResumeContext.Provider
      value={{
        loading,
        formAnswers,
        currentPage,
        totalPages,
        searchQuery,
        totalCount,
        selectedFormAnswers,
        resumeStats,
        allFormAnswersSelected,
        someFormAnswersSelected,
        formAnswerStates,
        filters,
        handleSearch,
        handlePageChange: setCurrentPage,
        handleSelectFormAnswer,
        handleSelectAllFormAnswers,
        changeFormAnswerState,
      }}
    >
      {children}
    </ResumeContext.Provider>
  );
}

export const useResumeContext = () => useContext(ResumeContext);
