import { useEffect, useState } from 'react';

import { SignatureCountry } from '../types';
import { checkRutChile, formatRutChile } from '../utils/rutUtils';

export function useNationalIdNumber(initialId: string) {
  const [value, setValue] = useState(initialId);
  const [valid, setValid] = useState(true);
  const [country, setCountry] = useState('');

  const checkNationalIdNumber = (id: string) => {
    switch (country) {
      case 'CL':
        return checkRutChile(id);
      default:
        return true;
    }
  };

  const formatNationalIdNumber = (id: string) => {
    switch (country) {
      case 'CL':
        return formatRutChile(id);
      default:
        return id.replace(/[-.]/g, '');
    }
  };

  useEffect(() => {
    setNationalIdNumber(value);
  }, [country]);

  const setNationalIdNumber = (id: string) => {
    const formattedId = formatNationalIdNumber(id);
    setValue(formattedId);
    setValid(checkNationalIdNumber(formattedId));
  };

  return {
    id: { value, valid, country: country as SignatureCountry },
    setNationalIdNumber,
    setCountry,
  };
}
