import { configureStore } from '@reduxjs/toolkit';

import currentTaskFormReducer from './modules/currentTaskForm.slice';
import groupsReducer from './modules/groups.slice';
import listManagerReducer from './modules/listManager.slice';
import listObjectsReducer from './modules/listObject.slice';
import locationsReducer from './modules/locations.slice';
import mobileUsersReducer from './modules/mobileUsers.slice';
import taskFormsReducer from './modules/taskForms.slice';
import templatesReducer from './modules/templates.slice';
import userListsReducer from './modules/userLists.slice';
import webFormsReducer from './modules/webForms.slice';

const store = configureStore({
  reducer: {
    userLists: userListsReducer,
    listObjects: listObjectsReducer,
    listManager: listManagerReducer,
    locations: locationsReducer,
    taskForms: taskFormsReducer,
    mobileUsers: mobileUsersReducer,
    currentTaskForm: currentTaskFormReducer,
    templates: templatesReducer,
    groups: groupsReducer,
    webForms: webFormsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
