import { initializeApp } from 'firebase/app';
import { User as FirestoreUser, getAuth, signInWithCustomToken } from 'firebase/auth';
import { Database, getDatabase } from 'firebase/database';
import { Firestore, getFirestore } from 'firebase/firestore';
import { Functions, getFunctions } from 'firebase/functions';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import { create } from 'zustand';

import { firestoreApi } from '../axios/firestore';

interface FirebaseStore {
  firestore: Firestore | null;
  user: FirestoreUser | null;
  firebaseConfigured: boolean;
  functions: Functions | null;
  storage: FirebaseStorage | null;
  rtDatabase: Database | null;
  initializeFirebase: () => Promise<void>;
}

const useFirebaseStore = create<FirebaseStore>((set) => ({
  firestore: null,
  user: null,
  firebaseConfigured: false,
  functions: null,
  storage: null,
  rtDatabase: null,
  initializeFirebase: async () => {
    try {
      const authData = await firestoreApi.getFirebaseAuthTokens();

      if (!authData.firebase) return;

      const firebaseConfig = {
        apiKey: authData.firebase.api_key,
        authDomain: authData.firebase.auth_domain,
        databaseURL: authData.firebase.database_base_url,
        projectId: authData.firebase.project_id,
        storageBucket: authData.firebase.storage_bucket,
        messagingSenderId: authData.firebase.messaging_sender_id,
        appId: authData.firebase.app_id,
      };

      const firebase = initializeApp(firebaseConfig);
      const auth = getAuth(firebase);
      if (!auth.currentUser) await signInWithCustomToken(auth, authData.fCustomToken);

      set({
        firestore: getFirestore(firebase),
        functions: getFunctions(firebase),
        storage: getStorage(firebase),
        rtDatabase: getDatabase(firebase),
        user: auth.currentUser,
        firebaseConfigured: true,
      });
    } catch (error) {
      console.error('Failed to initialize Firebase:', error);
    }
  },
}));

useFirebaseStore.getState().initializeFirebase();

export { useFirebaseStore };
