import { BillingDetails } from '../types/billing';
import { client } from './index';

async function getBillingDetails() {
  const response = await client.get<BillingDetails>('/billing_details');
  const { data } = response;
  return data;
}

async function getStripeCustomerPortalURL() {
  const response = await client.get<{ url: string }>('/stripe_customer_portal', {
    params: { return_url: `${process.env.REACT_APP_BACKEND_ENDPOINT}/billing` },
  });
  const { data } = response;
  return data;
}

async function updateStripeUserAmount(newUsers: number, oldUsers: number) {
  const response = await client.post('/modify_subscription_react', {
    n_users: newUsers,
    old_quantity: oldUsers,
  });
  const { data } = response;
  return data;
}

export const billingApi = {
  getBillingDetails,
  getStripeCustomerPortalURL,
  updateStripeUserAmount,
};
