import { TemplateResponse } from './../types';
import { client } from './index';

async function getTemplates(page: number, search?: string, category?: string, locale?: string) {
  const params = new URLSearchParams();

  params.append('page', (page + 1).toString());
  if (search) params.append('search', search);
  if (category) params.append('category', category);
  if (locale) params.append('loc', locale);

  const getTemplatesUrl = `/react_all_templates?${params.toString()}`;

  const response = await client.get<TemplateResponse>(getTemplatesUrl);
  const { data } = response;
  return data;
}

async function getCategories(locale: string) {
  const params = new URLSearchParams();

  params.append('loc', locale);

  const getCategoriesUrl = `/react_get_categories?${params.toString()}`;

  const response = await client.get<string[]>(getCategoriesUrl);
  const { data } = response;
  return data;
}

export const templatesApi = {
  getTemplates,
  getCategories,
};
