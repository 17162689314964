import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';

const initialState = {
  isActive: false,
  showPanel: false,
  segment: 'LISTS',
  rightColumn: '',
};

export const listManagerSlice = createSlice({
  name: 'listManager',
  initialState,
  reducers: {
    setIsActive: (state, action) => {
      state.isActive = action.payload;
    },
    openPanel: (state) => {
      state.showPanel = true;
    },
    closePanel: (state) => {
      state.showPanel = false;
    },
    setSegment: (state, action) => {
      state.segment = action.payload;
    },
    setRightColumn: (state, action) => {
      state.rightColumn = action.payload;
    },
  },
});

export const { setIsActive, openPanel, closePanel, setSegment, setRightColumn } =
  listManagerSlice.actions;
export const selectListManager = (state: RootState) => state.listManager;
export default listManagerSlice.reducer;
