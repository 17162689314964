import { useUserDataStore } from '../newStore/userDataStore';

export function usePermissionFlags() {
  const { permissions } = useUserDataStore();

  const checkPermission = (permissionToCheck: string) => {
    if (permissions) {
      return permissions.some((permission: string) => permissionToCheck === permission);
    }
    return false;
  };

  return {
    permissions,
    checkPermission,
  };
}
