import { useEffect, useMemo, useRef } from 'react';

import debounce from 'lodash/debounce';

// accepts delay in ms
export function useDebounce(callback: () => void, delay: number) {
  const ref = useRef<() => void>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const callback = () => {
      ref.current?.();
    };

    return debounce(callback, delay);
  }, [delay]);

  return debouncedCallback;
}
