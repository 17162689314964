import { NationalIdNumber, UserGroup } from '../../../types';

// Reducers that will manage the state of the different
// form fields from the MobileUserNew component.

// Information Section:
export interface NewMobileUserInformationState {
  name: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  oldPassword?: string;
  newPassword?: string;
}
export interface NewMobileUserInformationAction {
  type: string;
  value: string;
}
export function newUserInformationReducer(
  state: NewMobileUserInformationState,
  action: NewMobileUserInformationAction,
) {
  switch (action.type) {
    case 'update_user_name': {
      return {
        ...state,
        name: action.value,
      };
    }
    case 'update_user_last_name': {
      return {
        ...state,
        lastName: action.value,
      };
    }
    case 'update_user_email': {
      return {
        ...state,
        email: action.value,
      };
    }
    case 'update_user_password': {
      return {
        ...state,
        password: action.value,
      };
    }
    case 'update_user_phone': {
      return {
        ...state,
        phone: action.value,
      };
    }
    case 'update_user_old_password': {
      return {
        ...state,
        oldPassword: action.value,
      };
    }
    case 'update_user_new_password': {
      return {
        ...state,
        newPassword: action.value,
      };
    }
    default:
      throw Error('Unknown action: ' + action.type);
  }
}

// Group Section:
export interface GroupSelection extends UserGroup {
  selected: boolean;
}
export interface NewMobileUserGroupState {
  createdGroupName: string;
  createdGroupFormIds: number[];
  groupsSelection: GroupSelection[];
}

export interface NewMobileUserGroupAction {
  type: 'update_create_group_name' | 'update_groups_selection' | 'update_created_group_form_ids';
  updatedCreatedName?: string;
  updatedCreatedGroupFormIds?: number[];
  updatedGroupsSelection?: GroupSelection[];
}

export function newUserGroupReducer(
  state: NewMobileUserGroupState,
  action: NewMobileUserGroupAction,
) {
  switch (action.type) {
    case 'update_create_group_name': {
      return {
        ...state,
        createdGroupName: action.updatedCreatedName, // update create group name
      };
    }
    case 'update_created_group_form_ids': {
      return {
        ...state,
        createdGroupFormIds: action.updatedCreatedGroupFormIds, // update the created group's form ids
      };
    }
    case 'update_groups_selection': {
      return {
        ...state,
        groupsSelection: action.updatedGroupsSelection, // update groups selection
      };
    }
    default:
      throw Error('Unknown action: ' + action.type);
  }
}

// Forms Section:
export interface NewMobileUserFormState {
  formsSelection: number[];
}

export interface NewMobileUserFormAction {
  type: 'update_forms_selection';
  value: number[];
}

export function newUserFormReducer(
  _state: NewMobileUserFormState,
  action: NewMobileUserFormAction,
) {
  switch (action.type) {
    case 'update_forms_selection': {
      return {
        formsSelection: action.value, // update forms selection
      };
    }
    default:
      throw Error('Unknown action: ' + action.type);
  }
}

// Location Section:
export interface NewMobileUserLocationState {
  locationsSelectionIds: number[];
}

export interface NewMobileUserLocationAction {
  type: 'update_locations_selection';
  value: number[];
}

export function newUserLocationReducer(
  _state: NewMobileUserLocationState,
  action: NewMobileUserLocationAction,
) {
  switch (action.type) {
    case 'update_locations_selection': {
      return {
        locationsSelectionIds: action.value, // update locations selection
      };
    }
    default:
      throw Error('Unknown action: ' + action.type);
  }
}

export interface NewMobileUserSignatureState {
  name: string;
  email: string;
  id: NationalIdNumber;
}
