import { Role } from '../../types';
import { Permissions, PermissionVariant } from './mobileUsersPermissionsSelectorReducer';

export const rolePermissions: Record<Role, Record<PermissionVariant, Permissions>> = {
  ADMINISTRATOR: {
    basic: {
      viewFormAnswers: true,
      chat: true,
      submitFormAnswers: true,
      viewAssignedForms: true,
    },
    advanced: {
      changeFormStatus: true,
      createMetadata: true,
      editFormAnswers: true,
      editForms: true,
      evaluateDelete: true,
    },
    admin: {
      viewAndCreateUsers: true,
      exportData: true,
    },
  },

  SUPERVISOR: {
    basic: {
      viewFormAnswers: true,
      chat: true,
      submitFormAnswers: true,
      viewAssignedForms: true,
    },
    advanced: {
      changeFormStatus: true,
      createMetadata: true,
      editFormAnswers: true,
      editForms: false,
      evaluateDelete: true,
    },
    admin: {
      viewAndCreateUsers: false,
      exportData: true,
    },
  },

  EDITING_OPERATOR: {
    basic: {
      viewFormAnswers: true,
      chat: true,
      submitFormAnswers: true,
      viewAssignedForms: true,
    },
    advanced: {
      changeFormStatus: false,
      createMetadata: false,
      editFormAnswers: true,
      editForms: false,
      evaluateDelete: false,
    },
    admin: {
      viewAndCreateUsers: false,
      exportData: false,
    },
  },

  OPERATOR: {
    basic: {
      viewFormAnswers: false,
      chat: true,
      submitFormAnswers: true,
      viewAssignedForms: true,
    },
    advanced: {
      changeFormStatus: false,
      createMetadata: false,
      editFormAnswers: false,
      editForms: false,
      evaluateDelete: false,
    },
    admin: {
      viewAndCreateUsers: false,
      exportData: false,
    },
  },
};

export const rolePermissionsList = Object.keys(rolePermissions) as Array<
  keyof typeof rolePermissions
>;
