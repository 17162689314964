import { TaskForm } from './../types';
import { client } from './index';

async function getTaskForm(formId: number) {
  const response = await client.get<TaskForm>(`/task_forms_get/${formId}`);
  const { data } = response;
  return data;
}

interface GetPublicLinkResponse {
  is_active: boolean;
  public_link: string;
}
async function getPublicLink(formId: number) {
  const response = await client.get<GetPublicLinkResponse>(`/react_get_public_access/${formId}`);
  const { data } = response;
  return data;
}

async function addFormPublicAccess(formId: number) {
  const response = await client.get<TaskForm>(`/add_public_access?id=${formId}&react=react`);
  const { data } = response;
  return data;
}

async function removeFormPublicAccess(formId: number) {
  const response = await client.get<TaskForm>(`/remove_public_access?id=${formId}&react=react`);
  const { data } = response;
  return data;
}

interface ShareTaskFormByEmailResponse {
  invitations_sent: string;
}
async function shareTaskFormByEmail(formId: number, emails: string) {
  const response = await client.post<ShareTaskFormByEmailResponse>(
    '/share_form',
    {
      emails,
      react: 'react',
      id: formId,
    },
    {
      headers: {
        accept: 'application/json',
      },
    },
  );
  const { data } = response;
  return data;
}

export const currentTaskFormApi = {
  getTaskForm,
  getPublicLink,
  shareTaskFormByEmail,
  addFormPublicAccess,
  removeFormPublicAccess,
};
