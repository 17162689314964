import { ActivatedMobileUserMinimumData, ExportFindingBody } from '../types';
import { client } from './index';

async function exportFinding(findingId: string, body: ExportFindingBody) {
  const url = `/findings/${findingId}/generate_pdf`;

  const response = await client.post(url, body);
  const { data } = response;
  return data;
}

async function getAllMobileUsersForFindings() {
  const response = await client.get<ActivatedMobileUserMinimumData[]>(
    '/mobile_users_react/findings/all',
  );
  const { data } = response;
  return data;
}

async function activateFindingsDemo() {
  const response = await client.post('/users/activate_demo_flag', {
    key: 'Findings',
  });
  const { data } = response;
  return data;
}

export const findingsApi = {
  exportFinding,
  getAllMobileUsersForFindings,
  activateFindingsDemo,
};
