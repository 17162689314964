import { useUserDataStore } from '../newStore/userDataStore';

export function useProjectConfig() {
  const { currentUser } = useUserDataStore();

  return {
    blockFormBuilder: currentUser?.project?.block_editing_creating_forms,
    formsAllowedPerAccount: currentUser?.project?.forms_allowed_per_account,
    projectName: currentUser?.project?.name ?? '',
  };
}
