import { rotateImage } from '../utils/fixRotatedImage';
import {
  FormSearchResponse,
  FormTableResponse,
  TaskForm,
  TaskFormEmailInfo,
  TaskFormFolder,
  TaskFormSignatureData,
  TaskFormSignatureDataBody,
} from './../types';
import { client } from './index';

async function getTaskForms(page: number, folderId?: number, search?: string) {
  const params = new URLSearchParams();

  params.append('page', page.toString());
  if (folderId) params.append('folder_id', folderId.toString());
  if (search) params.append('search', search);

  const response = await client.get<FormTableResponse>(`/task_forms.json?${params.toString()}`);
  const { data } = response;
  return data;
}

// This request gets ALL task forms that the account has. If you use this, you MUST use virtualization.
async function getAllTaskForms(search?: string, folderId?: number) {
  const params = new URLSearchParams();
  if (search) params.append('search', search);
  if (folderId) params.append('folder_id', folderId.toString());

  const queryString = params.toString() ? `?${params.toString()}` : '';
  const url = `/task_forms_all${queryString}`;

  const response = await client.get<FormTableResponse>(url);
  const { data } = response;
  return data;
}

async function getFormSearch(page: number, search: string) {
  const params = new URLSearchParams();
  params.append('page', page.toString());
  params.append('search', search);

  const queryString = params.toString() ? `?${params.toString()}` : '';
  const url = `/react_form_index${queryString}`;

  const response = await client.get<FormSearchResponse>(url);
  const { data } = response;
  return data;
}

async function getTaskFormsFolders() {
  const response = await client.get<TaskFormFolder[]>('/task_forms_folders.json');
  const { data } = response;
  return data;
}

async function getTaskFormsRootTable() {
  const response = await client.get<TaskForm[]>('/task_forms_root_table.json');
  const { data } = response;
  return data;
}

async function getTaskFormsTemplates() {
  try {
    const response = await client.get<TaskForm[]>('/task_forms_templates.json');
    const { data } = response;
    return data;
  } catch {
    return [];
  }
}

async function toggleHideTaskForm(formId: number) {
  const response = await client.patch<TaskForm>(`/task_forms/${formId}/toggle_hide_form_react`);
  const { data } = response;
  return data;
}

async function bulkUpdateFormVisibility(formIds: number[], hide: boolean) {
  const response = await client.post<TaskForm[]>(`/task_forms/bulk_update_visibility`, {
    task_form_ids: formIds,
    hide,
  });
  const { data } = response;
  return data;
}

async function cloneTaskForm(formId: number) {
  const response = await client.post<TaskForm>(`/task_forms/${formId}/clone_form_react`);
  const { data } = response;
  return data;
}

async function deleteTaskForm(formId: number) {
  const response = await client.delete<TaskForm>(`/task_forms/${formId}/destroy_form_react`);
  const { data } = response;
  return data;
}

async function bulkDeleteForms(formIds: number[]) {
  const response = await client.post('/task_forms/bulk_delete', { task_form_ids: formIds });
  const { data } = response;
  return data;
}

async function renameFolder(folderId: number, name: string) {
  const response = await client.patch<TaskFormFolder>(`/form_folders/${folderId}`, { name });
  const { data } = response;
  return data;
}

async function createFolder(name: string, taskForms: number[]) {
  const response = await client.post<TaskFormFolder>('/form_folders', {
    name,
    task_forms_ids: taskForms,
  });
  const { data } = response;
  return data;
}

async function deleteFolder(folderId: number) {
  const response = await client.delete(`/form_folders/${folderId}`);
  const { data } = response;
  return data;
}

async function moveForms({ formIds, destinationId }: { formIds: number[]; destinationId: number }) {
  const response = await client.post('/task_forms/move_forms.json', {
    task_forms_ids: formIds,
    folder_id: destinationId,
  });
  const { data } = response;
  return data;
}

async function uploadImage(
  originalImage: File,
  shareToken?: string,
  resize?: boolean,
  rotate?: boolean,
) {
  const imageData = new FormData();
  const image = rotate ? await rotateImage(originalImage) : originalImage;
  imageData.append('file', image);
  if (shareToken) imageData.append('share_token', shareToken);
  if (resize) imageData.append('resize', `${resize}`);

  const response = await client.post<{ url: string }>(
    `/upload_image_to_s3${shareToken ? `_public` : ''}`,
    imageData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  );
  const { data } = response;
  return data;
}

async function getTaskFormSignatureData(formId: number) {
  const response = await client.get<TaskFormSignatureData>(
    `/task_forms/${formId}/signatures/settings`,
  );
  const { data } = response;
  return data;
}

async function updateTaskFormSignatureData(
  formId: number,
  signatureRequestBody: TaskFormSignatureDataBody,
) {
  const response = await client.put<TaskFormSignatureData>(
    `/task_forms/${formId}/signatures/settings`,
    { ...signatureRequestBody },
  );
  const { data } = response;
  return data;
}

async function getTaskFormEmailInfo(formId: number) {
  const response = await client.get<TaskFormEmailInfo>(`/task_forms_email_info/${formId}`);
  const { data } = response;
  return data;
}

async function updateTaskFormEmailInfo({
  formId,
  emailRequestBody,
}: {
  formId: number;
  emailRequestBody: TaskFormEmailInfo;
}) {
  const response = await client.post<TaskFormEmailInfo>(`/task_forms_update_email_info/${formId}`, {
    ...emailRequestBody,
  });
  const { data } = response;
  return data;
}

async function verifyFormName(formId: string, formName: string) {
  const params = new URLSearchParams();

  params.append('form_id', formId);
  params.append('form_name', encodeURIComponent(formName));

  const response = await client.get<{ valid: boolean }>(
    `/task_forms_verify_form_name?${params.toString()}`,
  );
  const { data } = response;
  return data;
}

async function getFolderFormsPendingSignatures() {
  const response = await client.get<Record<string, number[]>>('/form_folders_pending_signatures');
  const { data } = response;
  return data;
}

export const taskFormsApi = {
  getTaskForms,
  getAllTaskForms,
  toggleHideTaskForm,
  bulkUpdateFormVisibility,
  cloneTaskForm,
  deleteTaskForm,
  bulkDeleteForms,
  getTaskFormsTemplates,
  getTaskFormsFolders,
  getTaskFormsRootTable,
  getFormSearch,
  renameFolder,
  createFolder,
  deleteFolder,
  moveForms,
  uploadImage,
  getTaskFormSignatureData,
  updateTaskFormSignatureData,
  getTaskFormEmailInfo,
  updateTaskFormEmailInfo,
  verifyFormName,
  getFolderFormsPendingSignatures,
};
