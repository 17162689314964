import { useUserDataStore } from '../newStore/userDataStore';

export function useFeatureFlags() {
  const { featureFlags, premiumFlags, loading } = useUserDataStore();

  const checkFlag = (flagToCheck: string) => {
    if (!featureFlags) return false;
    return featureFlags.some((flag: string) => flagToCheck === flag);
  };

  const getPremiumFlag = (flagToGet: string) => {
    if (!(flagToGet in premiumFlags)) return null;
    return premiumFlags[flagToGet];
  };

  return {
    featureFlags,
    loading,
    checkFlag,
    getPremiumFlag,
  };
}
