import {
  ActivatedMobileUserMinimumData,
  InviteUserBody,
  MobileUser,
  MobileUserCreateBody,
  MobileUserUpdateBody,
  ModificationLog,
} from '../types';
import { client } from './index';

interface PaginationInterface {
  page?: number;
  search?: string;
}

interface FetchUsersInterface {
  page: number;
  total_pages: number;
  total_users: number;
  users: MobileUser[];
}
interface FetchLogsInterface {
  page: number;
  total_pages: number;
  total_users: number;
  users: ModificationLog[];
}

interface UserInfo {
  mobile_user: {
    id: number;
    name: string;
    last_name: string;
    code: string;
    email: string;
    phone: string;
    identifier: string;
    answers: number;
    chat: number;
    tasks: number;
    assigned: number;
    evaluate: number;
    metadata: number;
    modify: number;
    edit_forms: number;
    evaluate_delete: number;
    users: number;
    export: number;
  };
  group: { id: number[] };
  location: { id: number[] };
  form: { id: number[] };
  signature: {
    full_name: string;
    personal_email: string;
    rut: string;
    country: string;
  };
  pending_signature_forms: { id: number[] };
  pending_signature_groups: { id: number[] };
}

async function getActivatedMobileUsers({ page = 1, search }: PaginationInterface) {
  const params = new URLSearchParams();
  params.append('page', page.toString());
  params.append('search', search);

  const response = await client.get<FetchUsersInterface>(
    `/mobile_users_react/activated?${params.toString()}`,
  );
  const { data } = response;
  return data;
}

async function getAllActivatedMobileUsers() {
  const response = await client.get<ActivatedMobileUserMinimumData[]>('/mobile_users_react/all');
  const { data } = response;
  return data;
}

async function getDeactivatedMobileUsers({ page = 1 }: PaginationInterface) {
  const response = await client.get<FetchUsersInterface>(
    `/mobile_users_react/deactivated?page=${page}`,
  );
  const { data } = response;
  return data;
}

async function getMobileUsersHistory({ page = 1 }: PaginationInterface) {
  const response = await client.get<FetchLogsInterface>(`/mobile_users_react/history?page=${page}`);
  const { data } = response;
  return data;
}

// The delete function deactivates a user
async function deleteMobileUser(mobileUserId: number) {
  const response = await client.delete<MobileUser>(`/mobile_users_react/delete/${mobileUserId}`);
  const { data } = response;
  return data;
}

async function bulkDeactivateMobileUsers(mobileUserIds: number[]) {
  const response = await client.delete<MobileUser>('/mobile_users_react/bulk_delete', {
    data: { mobile_user_ids: mobileUserIds },
  });
  const { data } = response;
  return data;
}

async function updateMobileUser(body: MobileUserUpdateBody) {
  const response = await client.put<MobileUser>(
    `mobile_users_react/update/${body.mobile_user.id}`,
    body,
  );
  const { data } = response;
  return data;
}

// TODO: Find how to combine this function with the previous one, since they have the same purpose but the typing is different.
async function editMobileUser(updateMobileUserParams: MobileUserCreateBody, mobileUserId: string) {
  const response = await client.put<MobileUser>(
    `mobile_users_react/update/${mobileUserId}`,
    updateMobileUserParams,
  );
  const { data } = response;
  return data;
}

// You use the update function to edit and to activate users
async function reactivateMobileUser(mobileUserId: number) {
  const response = await client.put<MobileUser>(`mobile_users_react/update/${mobileUserId}`, {
    mobile_user: {
      reactivate_user: 'true',
    },
    location: { id: [''] },
    form: { id: [''] },
  });
  const { data } = response;
  return data;
}

async function createMobileUser(body: MobileUserCreateBody) {
  const response = await client.post<MobileUser>('mobile_users_react/create', body);
  const { data } = response;
  return data;
}

async function validateNewUserEmail(email: string) {
  try {
    const response = await client.get<{ email_exists: boolean }>('/validate_params', {
      params: { email },
    });
    return response.data;
  } catch (error) {
    console.log('There was an error validating the new email', error);
  }
}

async function inviteUser(body: InviteUserBody) {
  const response = await client.post<{ error?: string; message?: string }>(
    'mobile_users_react/invite',
    body,
  );
  const { data } = response;
  return data;
}

async function reInviteUser(mobileUserId: string) {
  const response = await client.post<{ error?: string; message?: string }>(
    '/mobile_users_react/re_invite',
    {
      user_id: mobileUserId,
    },
  );
  const { data } = response;
  return data;
}

async function getUserInfo(mobileUserId: string) {
  const response = await client.get<UserInfo>(`/mobile_users_react/${mobileUserId}`);
  const { data } = response;
  return data;
}

export const mobileUsersApi = {
  getActivatedMobileUsers,
  getAllActivatedMobileUsers,
  getDeactivatedMobileUsers,
  getMobileUsersHistory,
  deleteMobileUser,
  bulkDeactivateMobileUsers,
  updateMobileUser,
  reactivateMobileUser,
  createMobileUser,
  validateNewUserEmail,
  inviteUser,
  reInviteUser,
  getUserInfo,
  editMobileUser,
};
